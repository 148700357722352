import { defu } from 'defu'
import type { UseFetchOptions } from 'nuxt/app'

export function useAuthenticatedFetch<T> (url: string | (() => string), options: UseFetchOptions<T> = {}) {
  const { status } = useAuth()
  const { authorizationHeader } = useAuthorizationHeader()
  const { queryParameters } = useCounterpartyCookie()
  const config = useRuntimeConfig()

  const defaults: UseFetchOptions<T> = {
    baseURL: config.app.baseUrl ?? '/',
    // this overrides the default key generation, which includes a hash of
    // url, method, headers, etc. - this should be used with care as the key
    // is how Nuxt decides how responses should be deduplicated between
    // client and server
    // key: url,

    // set user token if connected
    headers: status.value === 'authenticated'
      ? authorizationHeader
      : {},
    query: queryParameters.value,
    onRequestError: onRequestErrorInterceptor,
    onResponseError: onResponseErrorInterceptor
  }
  if (typeof options.onRequestError === 'function') {
    options.onRequestError = (_ctx) => {
      onRequestErrorInterceptor(_ctx)
      // @ts-ignore
      options.onRequestError(_ctx)
    }
  }
  if (typeof options.onResponseError === 'function') {
    options.onResponseError = async (_ctx) => {
      await onResponseErrorInterceptor(_ctx)
      // @ts-ignore
      options.onResponseError(_ctx)
    }
  }

  // for nice deep defaults, please use unjs/defu
  const params = defu(options, defaults)

  return useFetch(url, params)
}
