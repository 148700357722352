import { type FetchContext } from 'ofetch'

export const onRequestErrorInterceptor = (_ctx: FetchContext & { error: Error }) => {
  const { $toast } = useNuxtApp()
  $toast.add({
    severity: 'error',
    summary: 'Ошибка',
    detail: _ctx?.error?.message || _ctx?.response?._data,
    life: 3000,
    group: 'app'
  })
}
