export const useAuthorizationHeader = () => {
  const { token, status } = useAuth()
  const authorizationHeader = reactive<Record<'Authorization', string | null>>({
    Authorization: ''
  })
  if (status.value === 'authenticated') {
    authorizationHeader.Authorization = token.value
  } else {
    Object.assign(authorizationHeader, {})
  }
  watch(token, (accessToken: string | null = '') => {
    if (accessToken) {
      authorizationHeader.Authorization = accessToken
    } else {
      Object.assign(authorizationHeader, {})
    }
  })
  return { authorizationHeader }
}
