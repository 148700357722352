import type { FetchContext, FetchResponse } from 'ofetch'

export const onResponseErrorInterceptor = async <R> (_ctx: FetchContext & { response: FetchResponse<R> }) => {
  const { $toast } = useNuxtApp()
  const { refresh } = useAuth()
  if (_ctx.response.status === 401) {
    await refresh()
  } else {
    $toast.add({
      severity: 'error',
      summary: 'Ошибка',
      detail: _ctx.response.statusText ? _ctx.response.statusText : _ctx.error?.message,
      life: 3000,
      group: 'app'
    })
  }
}
